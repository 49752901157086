import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  uptodateTitle: {
    id: 'insurance_due_uptodate_title',
    defaultMessage: 'Your payment is up to date',
  },
  uptodateMessage: {
    id: 'insurance_due_uptodate_message',
    defaultMessage:
      'Nice work, your tech is protected. Now, there’s nothing left for you to do but sit back, relax, and maybe take a fresh look around our site.',
  },
  uptodateButton: {
    id: 'insurance_due_uptodate_cta',
    defaultMessage: 'Browse sweet deals',
  },
}

export default translations
