<template>
  <div
    class="flex flex-col items-center justify-around px-24 py-40 md:flex-row md:p-40"
  >
    <div class="max-w-400">
      <div class="heading-1">
        {{ i18n(translations.uptodateTitle) }}
      </div>
      <div class="body-1 mt-20">
        {{ i18n(translations.uptodateMessage) }}
      </div>
      <div class="body-1 mt-20">
        <RevButton :to="buttonLink" variant="primary">
          {{ i18n(translations.uptodateButton) }}
        </RevButton>
      </div>
    </div>
    <div class="mt-24 md:mt-0">
      <RevIllustration
        alt=""
        aria-hidden="true"
        :height="420"
        src="/img/insurances/dueInsurancePayed.svg"
        :width="397.5"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import { HOME } from '~/scopes/home/route-names'

import translations from './DueInsuranceCompleted.translations'

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const buttonLink = toNuxtLink(injectLocale({ name: HOME }))
</script>
